import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

// @mui
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// custom imports
import palette from 'src/@core/theme/palette';
import EduInfoTab from './Tabs/EduInfoTab';
import SiblingInfoTab from './Tabs/SiblingInfoTab';
import StuckOffModal from './Tabs/StruckOffModal';
import urls from 'src/constants/configs/routApis';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import FamilyInfoTab from './Tabs/FamilyInfoTab';
import { dummyProfileURL, encodeQueryParams, formatDate } from 'src/Utils/helperFunc';
import { studentImageBaseURL } from 'src/constants/configs';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { useSessionKey } from 'src/hooks/useSessionKey';
import useUser from 'src/hooks/useUser';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfileSkeleton from 'src/components/profileSkeleton';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%'
        //   backgroundColor: '#635ee7',
    },
    '& .MuiTabs-flexContainer': {
        gap: '8px',
        flexWrap: 'wrap'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    padding: '20px 30px',
    border: `2px solid ${palette.primary.main}`,
    borderRadius: '10px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: 'black',
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: `${palette.primary.main}`
    },
    '&.Mui-focusVisible': {
        backgroundColor: `${palette.primary.main}`
    }
}));
const StyledButton = styled((props) => <Button disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    padding: '20px 30px',
    border: `2px solid ${palette.primary.main}`,
    borderRadius: '10px',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    color: 'black',
    '&.Mui-selected': {
        color: '#fff',
        backgroundColor: `${palette.primary.main}`
    },
    '&.Mui-focusVisible': {
        backgroundColor: `${palette.primary.main}`
    }
}));

function StudentProfile() {
    const location = useLocation();
    const studentId = location?.state?.id;

    const [tabs, setTabs] = useState(0);
    const [stuckOffModal, setStuckOffModal] = useState(false);
    const [profileFetching, setprofileFetching] = useState(false);
    const [studentData, setStudentData] = useState({});

    const key = useSessionKey();
    const { USERDATA: user } = useUser();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleStudentDocumentOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleStudentDocumentClose = () => {
        setAnchorEl(null);
    };

    const { refetch: refetchProfile } = useQuery({
        queryKey: ['student-profile'],
        queryFn: async () => {
            const queryParams = { id: studentId };
            const queryString = encodeQueryParams(queryParams);
            setprofileFetching(true);
            const { data } = await fetchWrapper('GET', `${urls.STD_PROFILE}?${queryString}`);
            setprofileFetching(false);
            if (data?.status === 200) {
                setStudentData(data?.data);
            }
            return data;
        },
        enabled: Boolean(studentId)
    });

    useEffect(() => {
        if (studentId) {
            refetchProfile();
        }
    }, [location?.state?.id]);

    const handleTabsChange = (event, newValue) => {
        setTabs(newValue);
    };
    let basicInfo = [
        { label: 'Name in Urdu', value: studentData?.urduName },
        { label: 'Gender', value: studentData?.gender },
        { label: 'Religion', value: studentData?.religion },
        { label: 'Place Of Birth', value: studentData?.placeOfBirth },
        { label: 'Date of Admission', value: formatDate(studentData?.dateOfAdms) },
        { label: 'B-Form No', value: studentData?.formBNo },
        { label: 'Cast', value: studentData?.cast }
    ];

    const placeholderKey = 'a61oe8lqc1oclfi4llgnioo156a61oe8lqc1oclfi4llgnioo156a61oe8lqc1oclfi4llgnioo156a61oe8lqc1oclfi4llgnioo156a61oe8lqc1oclfi4llgnioo156';
    const studentProfileImage = studentData?.image
        ? studentData?.image.includes('computech-media-content.s3')
            ? studentData?.image
            : studentImageBaseURL + studentData?.image
        : dummyProfileURL;
    if (profileFetching) return <ProfileSkeleton />;
    return (
        <Fragment>
            <Paper sx={{ p: 3, boxShadow: 10 }}>
                <Box>
                    <StyledTabs value={tabs} onChange={handleTabsChange} aria-label="basic tabs example">
                        <StyledTab label="Personal Info" {...a11yProps(0)} />
                        <StyledTab label="Family Info" {...a11yProps(1)} />
                        <StyledTab label="Siblings Info" {...a11yProps(2)} />
                        {/* <StyledTab label="Exam Results" {...a11yProps(3)} /> */}
                        {/* <StyledTab label="Fee History" {...a11yProps(4)} /> */}
                        {/* <StyledTab label="Attendance" {...a11yProps(5)} /> */}
                        <StyledButton
                            onClick={() => window.open(externalLinkGenerator('/student_profile_result.php', { placeholderKey, student: studentData.studentId,key }))}
                            variant="outlined"
                            sx={{ borderWidth: '2px', '&:hover': { borderWidth: '2px' } }}
                        >
                            Exam
                        </StyledButton>
                        <StyledButton
                            onClick={() =>
                                window.open(
                                    externalLinkGenerator('/print_monthly_fee_report.php', {
                                        placeholderKey,
                                        std: studentData.studentId,
                                        session: moment().year(),
                                        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                                        key
                                    })
                                )
                            }
                            variant="outlined"
                            sx={{ borderWidth: '2px', '&:hover': { borderWidth: '2px' } }}
                        >
                            Fee History
                        </StyledButton>
                        <StyledButton
                            onClick={() =>
                                window.open(
                                    externalLinkGenerator('/print_students_attendance_history.php', {
                                        placeholderKey,
                                        student: studentData.studentId,
                                        class_id: studentData.class_id,
                                        section_id: studentData.section_id,
                                        session: user.sessionId,
                                        key
                                    })
                                )
                            }
                            variant="outlined"
                            sx={{ borderWidth: '2px', '&:hover': { borderWidth: '2px' } }}
                        >
                            Attendance
                        </StyledButton>
                        <StyledButton onClick={handleStudentDocumentOpen}>
                            Student Documents <ExpandMoreIcon />
                        </StyledButton>
                    </StyledTabs>
                </Box>
            </Paper>
            {tabs === 2 ? (
                <Grid item xs={12}>
                    <CustomTabPanel value={tabs} index={2}>
                        <SiblingInfoTab studentData={studentData} />
                    </CustomTabPanel>
                </Grid>
            ) : (
                <Grid container mx="auto">
                    <Grid item xs={12} md={6} style={{ padding: '10px 5px 0 0' }}>
                        <Paper
                            elevation={4}
                            sx={{
                                p: 3,
                                mt: 2,
                                boxShadow: 10,
                                overflowY: 'auto',
                                maxHeight: '500px',
                                height: '440px'
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Box
                                        component="img"
                                        src={studentProfileImage}
                                        alt="Student Image"
                                        sx={{
                                            width: '100%',
                                            borderRadius: '8px',
                                            border: `3px solid ${palette.primary.main}`,
                                            mb: 2
                                        }}
                                    />
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Student:
                                    </Typography>
                                    <Typography variant="h6">{studentData?.firstName}</Typography>
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Date of Birth:
                                    </Typography>
                                    <Typography variant="body2">{formatDate(studentData?.dob)}</Typography>
                                    <Button style={{ marginTop: '1rem' }} onClick={() => setStuckOffModal(true)} variant="contained">
                                        Stuck Off
                                    </Button>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container spacing={2} sx={{ mt: 4 }}>
                                        {basicInfo.map((el) => (
                                            <Grid item xs={6}>
                                                <Typography variant="body2">
                                                    <strong>{el.label}:</strong>&nbsp; {el.value || '--'}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: '10px 0 0 5px' }}>
                        <Paper elevation={4} sx={{ p: 3, mt: 2, boxShadow: 10, maxHeight: '500px', height: '440px', overflow: 'auto' }}>
                            <CustomTabPanel value={tabs} index={0}>
                                <EduInfoTab studentData={studentData} />
                            </CustomTabPanel>
                            <CustomTabPanel value={tabs} index={1}>
                                <FamilyInfoTab studentData={studentData} />
                            </CustomTabPanel>
                        </Paper>
                    </Grid>
                </Grid>
            )}

            <StuckOffModal studentData={studentData} open={stuckOffModal} onClose={() => setStuckOffModal(false)} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleStudentDocumentClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_birth_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Birth Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/adm_form_standard.php', {
                                placeholderKey,
                                student: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Admission Form
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_character_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Character Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/student_profile_details.php', {
                                placeholderKey,
                                student: studentData.studentId,
                                class_id: studentData.class_id,
                                section_id: studentData.section_id,
                                session: user.sessionId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Exam Report
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_study_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Study Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(externalLinkGenerator('/customize_provisional_certificate.php', { placeholderKey, student_id: studentData.studentId,key }));
                        handleStudentDocumentClose();
                    }}
                >
                    Provisional Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_sports_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Sports Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_appreciation_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Appreciation Certificate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.open(
                            externalLinkGenerator('/customize_leaving_certificate.php', {
                                placeholderKey,
                                student_id: studentData.studentId,
                                key
                            })
                        );
                        handleStudentDocumentClose();
                    }}
                >
                    Leaving Certificate
                </MenuItem>
            </Menu>
        </Fragment>
    );
}

export default StudentProfile;
